<template>
  <div class="page flex-row justify-center align-center">
    <div class="content" v-if="!directErr">
      <div
        v-if="payStatus == 0 && orderData.payType == 1"
        class="success-content flex-col justify-center align-center"
      >
        <span class="wxPay-title">微信支付</span>
        <span class="money">￥{{ orderData.payAmount }}</span>
        <div class="flex-col center-info align-center">
          <span>您的订单编号：{{ orderId }}</span>
          <span v-show="minutes && second" style="color: #999999"
            >请在<span style="color: #2bb3e3"
              >{{ minutes }}分钟{{ second }}秒</span
            >内付款，否则订单会被自动取消。</span
          >
          <img
            v-if="orderData.wxpayPayUrl"
            :src="'data:image/png;base64,' + orderData.wxpayPayUrl"
          />
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" @click="goHome">返回首页</div>
          <div id="primary-btn" @click="goDetail">查看订单</div>
        </div>
      </div>
      <div
        v-if="payStatus == 0 && orderData.payType == 2"
        class="success-content flex-col justify-center align-center"
      >
        <span class="wxPay-title">支付宝</span>
        <span class="money">￥{{ orderData.payAmount }}</span>
        <div class="flex-col center-info align-center">
          <span>您的订单编号：{{ orderId }}</span>
          <span v-show="minutes && second" style="color: #999999"
            >请在<span style="color: #2bb3e3"
              >{{ minutes }}分钟{{ second }}秒</span
            >内付款，否则订单会被自动取消。</span
          >
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" @click="goHome">返回首页</div>
          <div id="primary-btn" @click="goDetail">查看订单</div>
        </div>
      </div>
      <div
        v-if="payStatus == 0 && orderData.payType == 3"
        class="success-content flex-col justify-center align-center"
      >
        <span class="wxPay-title">花呗支付</span>
        <span class="money">￥{{ orderData.payAmount }}</span>
        <div class="flex-col center-info align-center">
          <span>您的订单编号：{{ orderId }}</span>
          <span v-show="minutes && second" style="color: #999999"
            >请在<span style="color: #2bb3e3"
              >{{ minutes }}分钟{{ second }}秒</span
            >内付款，否则订单会被自动取消。</span
          >
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" @click="goHome">返回首页</div>
          <div id="primary-btn" @click="goDetail">查看订单</div>
        </div>
      </div>
      <div
        v-if="payStatus == 0 && orderData.payType == 4"
        class="success-content flex-col justify-center align-center"
      >
        <span class="wxPay-title">支付宝支付</span>
        <span class="money">￥{{ orderData.payAmount }}</span>
        <div class="flex-col center-info align-center">
          <span>您的订单编号：{{ orderId }}</span>
          <span v-show="minutes && second" style="color: #999999"
            >请在<span style="color: #2bb3e3"
              >{{ minutes }}分钟{{ second }}秒</span
            >内付款，否则订单会被自动取消。</span
          >
          <img
            v-if="orderData.alipayUrl"
            :src="'data:image/png;base64,' + orderData.alipayUrl"
          />
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" @click="goHome">返回首页</div>
          <div id="primary-btn" @click="goDetail">查看订单</div>
        </div>
      </div>
      <div
        v-if="
          payStatus == 1 || payStatus == 3 || payStatus == 4 || payStatus == 5
        "
        class="success-content flex-col justify-center align-center"
      >
        <div class="pay-icon flex-col justify-center align-center">
          <img src="../../assets/icon/tick-circle.png" alt="" />
          <span style="color: #2bb3e3">支付成功</span>
        </div>
        <div class="flex-col center-info">
          <div class="success-share flex-row align-center">
            <span class="orderId">您的订单编号：{{ orderId }}</span>
            <div class="share flex-row justify-center align-center" @click="onShareOrder">
              <img class="share-btn" src="@/assets/img/share.png" alt="" />
              <span class="text-btn">分享订单</span>
            </div>
          </div>
          <span style="color: #acacac" v-if="orderData.direct"
            >该订单无需审核，视频正在投放中。</span
          >
          <span style="color: #acacac" v-else-if="isBetween"
            >我们将会在今天内完成审核，请您耐心等待。</span
          >
          <span style="color: #acacac" v-else
            >我们将会在明日大屏开机时间之前完成审核，请您耐心等待。</span
          >
          <span style="color: #acacac" v-if="!orderData.direct"
            >通过后在历史订单中再次投放该视频无需审核，最快可5分钟后投放。</span
          >
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" @click="goDetail">查看订单</div>
          <div id="shallow-primary-btn" @click="onClick_same()">投放同款广告</div>
          <div id="primary-btn" @click="onClick_create()">投放其它广告</div>
        </div>
      </div>
      <div
        v-if="payStatus == 2"
        class="cancel-content flex-col justify-center align-center"
      >
        <div class="pay-icon flex-col justify-center align-center">
          <img src="../../assets/icon/close-circle.png" alt="" />
          <span>已取消</span>
        </div>
        <div class="flex-col center-info">
          <span>您的订单编号：{{ orderId }}</span>
          <span>因超时未支付已被系统取消；若有需要，您可再次投放。</span>
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" @click="goHome">返回首页</div>
          <div id="primary-btn" @click="onRelaunch">再次投放</div>
        </div>
      </div>
    </div>
    <div class="content" v-else>
      <div
        class="cancel-content flex-col justify-center align-center"
      >
        <div class="pay-icon flex-col justify-center align-center">
          <img src="../../assets/icon/close-circle.png" alt="" />
          <span>支付失败</span>
        </div>
        <div class="flex-col center-info">
          <span>您的订单编号：{{ orderId }}</span>
          <span>因当前时间已过第一次投放时间，订单支付失败并被取消</span>
          <span>您可再次投放重新选择投放时间</span>
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" @click="goHome">返回首页</div>
          <div id="primary-btn" @click="onRelaunch">再次投放</div>
        </div>
      </div>
    </div>
    <re-launch ref="refReLaunch" :launchData="orderData"></re-launch>
    <share-order-dialog
      :shareData="shareData"
      ref="refShareOrder"
    ></share-order-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { getOrder, cancelOrder } from "@/api/request";
import reLaunch from "@/components/reLaunch.vue";
import { Loading } from "element-ui";
import shareOrderDialog from "@/components/shareOrderDialog.vue";

export default {
  components: {
    reLaunch,
    shareOrderDialog,
  },
  data() {
    return {
      payStatus: "0",
      orderId: null,
      payTimer: null, // 初始定时器变量名为null
      countDownTimer: null,
      orderData: {},
      minutes: 0,
      second: 0,
      loading: null,
      shareData: {}, // 分享弹窗的数据
      directErr: false, // 是否是免审视频超过了第一次投放时间
    };
  },
  computed: {
    // 判断当前时间是否在22:00 - 23:59:59
    isBetween() {
      const time = new Date();
      if (time.getHours() < 22) {
        return true;
      }
      return false;
    }
  },
  created() {
    // 微信二维码支付
    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId;
    }
    // 非微信二维码支付（跳转第三方支付，比如支付宝）
    if (this.$route.query.invoiceId) {
      this.orderId = this.$route.query.invoiceId;
    }
    this.getMyOrder(this.orderId, true);
    // 免审视频超过了第一次投放时间
    if (this.$route.query.directErr && this.$route.query.directErr == 1) {
      this.directErr = true;
      this.automaticCancelOrder();
      return;
    }
    this.payTimer = setInterval(() => {
      this.getMyOrder(this.orderId);
    }, 5000);
  },
  methods: {
    getMyOrder(orderId, isFirst = false) {
      if (isFirst) {
        this.loading = Loading.service({
          lock: true,
          background: "rgba(255, 255, 255, 0.8)",
        });
      }
      getOrder({
        order_id: orderId,
        include_ad: false,
      })
        .then((res) => {
          if (res.order.status !== "0") {
            this.clearTimer();
          }
          if (res.order.status == "0" && !this.countDownTimer) {
            this.countDown(res.order.cancelTime);
          }
          this.payStatus = res.order.status;
          this.orderData = res.order;
          if (isFirst) {
            this.loading.close();
          }
          // 如果未支付并且支付方式是支付宝支付
          if (res.order.status == 0 && res.order.payType == 2) {
            this.$router.replace({
              path: "/myOrder/detail",
              query: {
                orderId: res.order.orderId,
              },
            });
          }
        })
        .catch((code) => {
          this.clearTimer();
          if (isFirst) {
            this.loading.close();
          }
          // 订单不存在等需要跳转404页面的情况
          if (code == "20011" || code == "20017" || code == "10001") {
            this.$router.replace({ path: "/error" });
          }
        });
    },
    // 自动取消订单
    automaticCancelOrder() {
      cancelOrder({
        order_id: this.orderId,
      })
        .then(() => {
          console.log("自动取消订单成功");
        })
        .catch(() => {});
    },
    goHome() {
      this.$router.replace({ path: "/" });
    },
    goDetail() {
      const BASE_URL = process.env.VUE_APP_BASE_URL;
      window.open(`${BASE_URL}/myOrder/detail?orderId=${this.orderId}`);
    },
    countDown(futureTime) {
      const currentTime = parseInt(new Date().getTime() / 1000);
      console.log(futureTime, currentTime, futureTime - currentTime);
      if (currentTime >= futureTime) {
        return false;
      }
      let differSecond = futureTime - currentTime;
      const vm = this;
      function fn() {
        vm.minutes =
          parseInt(differSecond / 60) < 10
            ? `0${parseInt(differSecond / 60)}`
            : parseInt(differSecond / 60);
        vm.second =
          parseInt(differSecond - vm.minutes * 60) < 10
            ? `0${parseInt(differSecond - vm.minutes * 60)}`
            : parseInt(differSecond - vm.minutes * 60);
        if (vm.minutes == "00" && vm.second == "00") {
          vm.clearTimer();
          vm.getMyOrder(vm.orderId);
        }
        differSecond--;
        return fn;
      }
      this.countDownTimer = setInterval(fn(), 1000);
    },
    clearTimer() {
      if (this.payTimer) {
        clearInterval(this.payTimer);
      }
      if (this.countDownTimer) {
        clearInterval(this.countDownTimer);
      }
      this.payTimer = null;
      this.countDownTimer = null;
    },
    closeWeb() {
      window.close();
    },
    // 再次投放（编辑投放）
    onRelaunch() {
      this.$refs.refReLaunch.openreLaunch();
    },
    // 重新投放
    onClick_create() {
      this.$canvaUtil.onClickCreate();
    },
    // 投放同款广告
    onClick_same() {
      this.$router.replace({
        name: "AddLaunchInfo",
        query: {
          videoUrl: this.orderData.videoUrl,
          realVideoDuration: this.orderData.videoDuration,
        },
      });
    },
    // 分享订单
    onShareOrder() {
      this.shareData = {
        orderId: this.orderData.orderId,
        userName: this.orderData.userName,
        userImage: this.orderData.userImage,
      };
      setTimeout(() => {
        this.$refs.refShareOrder.openShareOrder();
      }, 100);
    },
  },
  beforeDestroy() {
    this.clearTimer();
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  min-height: calc(100vh - 233rem);
  background: #f8f8f8;
  .content {
    width: 45%;
    background-color: #fff;
    padding: 58rem 0 50rem 0;
    border-radius: 6rem;
    .success-content,
    .cancel-content {
      width: 100%;
      .wxPay-title {
        font-size: 28rem;
        color: #272927;
        line-height: 40rem;
      }
      .money {
        font-size: 28rem;
        color: #2bb3e3;
        line-height: 40rem;
        position: relative;
        top: 10rem;
      }
      .pay-icon {
        img {
          width: 70rem;
        }
        span {
          font-size: 28rem;
          color: #252927;
          line-height: 40rem;
          margin-top: 23rem;
        }
      }
      .center-info {
        background-color: #f8f8f8;
        padding: 40rem 66rem;
        margin: 42rem 0 50rem 0;
        border-radius: 12rem;
        span {
          font-size: 16rem;
          color: #515351;
          line-height: 25rem;
        }
        span:nth-child(2) {
          margin-top: 8rem;
        }
        img {
          width: 200rem;
          height: 200rem;
          margin-top: 30rem;
        }
        .success-share {
          .orderId {
            font-size: 16rem;
            color: #515351;
            line-height: 25rem;
          }
          .share {
            width: 120rem;
            height: 34rem;
            background: linear-gradient(225deg, #d3f4f6 0%, #daeefa 100%);
            border-radius: 25rem;
            margin-left: 15rem;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              background: linear-gradient(225deg, #b9f2f4 0%, #c8e7fb 100%);
            }
            .share-btn {
              width: 18rem;
              height: 18rem;
              margin-right: 6rem;
              margin-top: 0;
            }

            .text-btn {
              font-size: 16rem;
              font-weight: 500;
              color: #2bb3e3;
              line-height: 22rem;
              margin-top: 0;
            }
          }
        }
      }
      .btn-group {
        div {
          text-align: center;
          line-height: 50rem;
        }
        #default-btn {
          width: 168rem;
          height: 50rem;
          background: #ffffff;
          border-radius: 25rem;
          border: 1rem solid #32c3f6;
          color: #2bb3e3;
        }
        #primary-btn {
          width: 168rem;
          height: 50rem;
          border-radius: 25rem;
          border: none;
          color: #fff;
          margin-left: 48rem;
        }
        #shallow-primary-btn {
          width: 168rem;
          height: 50rem;
          border-radius: 25rem;
          border: none;
          color: #2BB3E3 ;
          margin-left: 48rem;
        }
      }
    }
  }
}
</style>
